@import 'src/variables/variables.scss';

.form {
  max-width: 580px;
  .input {
    margin-top: 72px;
    & > span {
      white-space: pre-wrap;
    }
    &:first-child {
      margin-top: 0;
    }
    @media(max-width: $MOBILE_WIDTH) {
      margin-top: 28px;
    }
  }
  .inputSocialLink {
    & > input {
      padding-bottom: 24px;
    }
  }
  .buttons {
    & > button {
      margin-top: 30px;
      width: 100%;
      &:first-child {
        margin-right: 36px;
        @media(max-width: $MOBILE_WIDTH) {
          margin-right: 0;
        }
      }
    }
  }
}

.applicationSuccess {
  font: {
    size: 26px;
  }
  line-height: 1.2;
  text-align: center;
  .applicationSuccessWrapper {
    text-align: center;
    margin-top: 40px
  }
}