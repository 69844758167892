@import 'src/variables/variables.scss';

.wrapper {
  max-width: 1240px;
  margin: 0 auto;
  position: relative;
  @media (max-width: $MOBILE_WIDTH) {
    margin: 0 16px;
  }
  .header {
    font-size: 56px;
    font-weight: bold;
    line-height: 66px;
    margin: {
      top: 88px;
      bottom: 64px;
    }
    text-align: center;
    z-index: 1;

    @media (max-width: $MOBILE_WIDTH) {
      margin-bottom: 36px;
      font-size: 36px;
      line-height: 42px;
    }
  }
  .packages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 590px));
    grid-row-gap: 56px;
    grid-column-gap: 56px;
    justify-content: center;
    cursor: default;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 30%;
    left: 92%;
    border-radius: 50%;
    box-shadow: 0 0 146px 65px #ff951d;
    z-index: 0;
  }
}