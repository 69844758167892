.button {
  border: none;
  outline: none;
  color: white;
  font: {
    weight: bold;
    size: 24px;
  }
  box-sizing: border-box;
  padding: 24px 46px;
  border-radius: 16px;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  &:hover, &:focus {
    cursor: pointer;
  }
  &:hover:before, &:focus:before, &:active:before {
    transform: scaleX(1);
  }
}

.green {
  background-color: #00C106;
  box-shadow: 0 0 20px 0 #00C106;
  &:before {
    background-color: #00a906;
  }
}

.black {
  background-color: #212121;
  box-shadow: 0 0 20px 0 #212121;
  &:before {
    background-color: #0d0d0d;
  }
}

.slideRightAnimation {
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    border-radius: 16px;
    transition-timing-function: ease-out;
  }
}
