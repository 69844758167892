@import 'src/variables/variables.scss';

.wrapper {
  padding: {
    top: 28px;
  }
  position: relative;

  .menu {
    display: flex;
    justify-content: center;
    background-color: rgba(49, 49, 49, 0.5);
    max-width: 1220px;
    margin: 0 auto;
    border: {
      radius: 36px;
    };
    font: {
      weight: 500;
    }
    z-index: 1;

    & li {
      margin-right: 56px;
      &:hover {
        background-color: rgba(20, 20, 20, 0.5);
      }
      &:last-child {
        margin-right: 0;
      }
      & > a {
        display: block;
        padding: 38px 20px;
      }
    }

    @media (max-width: $MOBILE_WIDTH) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 30%;
    left: 65%;
    border-radius: 50%;
    box-shadow: 0 0 140px 70px #ff951d;
    z-index: 0;
  }
}

.menuOverlayWrapper {
  display: none;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(15px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  color: white;
  font-weight: bold;
  overflow: auto;
  transform: translateY(0px);
  & :local {
    animation: slideInDown 0.5s ease-in;
  }
  &.slideInUp {
    & :local {
      animation: slideInUp 0.4s ease-out;
    }
  }
  .overlayMenu {
    position: relative;
    top: 50%;
    transform: translateY(-60%);
    & > h3 {
      text-align: center;
      margin: 0 auto;
      font: {
        size: 42px;
      }
      text-decoration: underline;
    }
    & > .overlayMenuLinks {
      text-align: center;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
      & > li {
        color: white;
        font-size: 28px;
        @media(max-width: 370px) {
          font-size: 30px
        }
        & > a {
          display: block;
          padding: 10px;
          &:hover, &:focus, &:active {
            background-color: rgba(20, 20, 20, 0.5);
          }
        }
      }
    }
  }
  &.menuOverlayWrapperActive {
    display: block;
    position: fixed;
  }
}

.menuButton {
  width: 34px;
  height: 32px;
  position: fixed;
  padding: 18px 28px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(49, 49, 49, 0.5);
  border-radius: 12px;
  margin: {
    top: 5px;
    left: auto;
    right: 40px;
  }
  z-index: 6;
  & > .menuButtonTopLine, & > .menuButtonCenterLine, & > .menuButtonBottomLine {
    transition: 0.2s;
    display: block;
    content: "";
    position: absolute;
    width: 34px;
    height: 4px;
    background-color: white;
  }
  & > .menuButtonTopLine {
    top: calc(50% - 2px);
  }
  & > .menuButtonBottomLine {
    bottom: 18px;
  }
  &.menuButtonActive {
    & > .menuButtonTopLine {
      transform: rotate(-45deg);
    }
    & > .menuButtonCenterLine {
      height: 0;
    }
    & > .menuButtonBottomLine {
      transform: rotate(45deg) translateX(-10px) translateY(-10px);
    }
  }
  @media (min-width: $MOBILE_WIDTH) {
    display: none;
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideInUp {
  from {
    opacity: 1;
    transform: translateY(0px);

  }

  to {
    opacity: 0.1;
    transform: translateY(-50%);
  }
}