@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}

p {
  margin: 0;
  padding: 0;
}

/* scrollbar */
/* for firefox */
:global(*) {
  scrollbar-color: #222121 transparent;
  scrollbar-width: thin;
}
:global(::-webkit-scrollbar) {
  width: 20px;
  height: 20px;
  background-color: #222121;
}

:global(::-webkit-scrollbar-thumb) {
  border: 8px solid rgba(0, 0, 0, 0);
  border-radius: 20px;
  box-shadow: inset -3px -3px 0 white, inset 1px 1px 0 white;
  &:hover {
    box-shadow: inset -3px -3px 0 white, inset 1px 1px 0 white;
  }
}

:global(::-webkit-scrollbar-button) {
  display: none;
}

/* end scrollbar */
