@import 'src/variables/variables.scss';

.packageCard {
  display: flex;
  flex-direction: column;
  background-color: #292929;
  border-radius: 36px;
  padding: 56px 66px;

  @media (max-width: $MOBILE_WIDTH) {
    padding: 28px 28px;
  }
  .packageCardInfo {
    .title {
      font: {
        size: 53px;
        weight: bold;
      }
      margin: 0;
      @media (max-width: $MOBILE_WIDTH) {
        font-size: 42px;
      }
    }

    .description {
      margin-top: 16px;
      font: {
        size: 24px;
        weight: 500;
      }
      line-height: 28px;
      @media (max-width: $MOBILE_WIDTH) {
        font-size: 20px;
      }
    }

    .notes {
      margin-top: 48px;
      font: {
        weight: 500;
        size: 24px;
      }
      line-height: 28px;
      & > li {
        margin-top: 16px;
      }
      @media (max-width: $MOBILE_WIDTH) {
        font-size: 20px;
      }
    }
  }

  .discount {
    margin-top: 36px;
    margin-bottom: 30px;
    color: #ea6307;
    font: {
      weight: 500;
      size: 24px;
    }
    white-space: nowrap;
    @media (max-width: $MOBILE_WIDTH) {
      font-size: 18px;
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
    line-height: 66px;
    font: {
      size: 52px;
      weight: bold;
    }
    & > span {
      display: block;
    }
    @media (max-width: $MOBILE_WIDTH) {
      font-size: 38px;
      line-height: 46px;
    }
  }

  .buttonWrapper {
    margin-top: 10px;
    @media (max-width: $MOBILE_WIDTH) {
      margin-top: 20px;
    }
  }
}