.main {
  min-height: 100vh;
  background-color: #222121;
  overflow-x: hidden;
}

.blur {
  filter: blur(12px);
}

:global(.disableMobileHighlights) {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}