@import 'src/variables/variables.scss';

.modal {
  backdrop-filter: blur(15px);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  transition: 0.5s;
  z-index: 11;
  min-height: 100vh;
  @supports (-moz-appearance:none) {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

.modalContent {
  background: rgba(33, 33, 33, .5);
  border-radius: 64px;
  max-width: 1240px;
  width: 100%;
  margin: 0 40px;
  padding: 100px 30px 100px 80px;
  @media(max-width: $MOBILE_WIDTH) {
    margin: 0 20px;
    border-radius: 42px;
    padding: 48px 30px 48px 30px;
  }
  & :local {
    animation: zoomIn 0.6s ease;
  }
}

.zoomOut {
  & :local {
    animation: zoomOut 0.6s ease;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
