@import 'src/variables/variables.scss';

.wrapper {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  max-width: 1260px;
  .textWithImage {
    margin: 30px 30px 0 30px;
    display: flex;
    z-index: 1;
    .mainText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 680px;
      width: 50%;
      line-height: 84px;
      font: {
        weight: bold;
        size: 72px;
      }
      @media (max-width: $MOBILE_WIDTH) {
        font-size: 36px;
        line-height: 42px;
        width: 100%;
      }
      z-index: 1;
      .titleFirstPart {
        display: inline-block;
        margin-bottom: 4px;
        color: #FF951D;
        white-space: nowrap;
      }
      .titleSecondPart {
        font-size: 58px;
        display: inline-block;
        line-height: 64px;
        @media (max-width: $MOBILE_WIDTH) {
          font-size: 30px;
          line-height: 34px;
          width: 75%;
        }
      }
      .additionalTextWrapper {
        position: relative;
        margin: {
          top: 38px;
          bottom: 48px;
        }
        @media (max-width: $MOBILE_WIDTH) {
          margin: {
            top: 16px;
            bottom: 24px;
          }
        }
        .additionalText {
          font: {
            weight: 500;
            size: 36px;
          }
          line-height: 36px;
          @media (max-width: $MOBILE_WIDTH) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
    .image {
      width: 50%;
      height: 100%;
      object-fit: contain;
      position: relative;
      @media screen and (max-width: 1235px) {
        transform: scale(0.8);
        left: -70px;
      }
      @media screen and (max-width: 996px) {
        transform: scale(0.6);
        top: -120px;
        left: -50px;
      }
      @media (max-width: $MOBILE_WIDTH) {
        top: 30px;
        left: auto;
        right: -40px;
        position: absolute;
        transform: none;
        width: 315px;
        height: 252px;
        overflow: hidden;
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 15%;
    left: 0;
    border-radius: 50%;
    box-shadow: 0 0 170px 45px #ff951d;
    z-index: 0;
  }
}