@import 'src/variables/variables.scss';

.input {
  display: block;
  position: relative;
  cursor: text;
  font: {
    weight: bold;
    size: 36px;
  }
  @media(max-width: $MOBILE_WIDTH) {
    font-size: 24px;
  }

  .inputField {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 12px 8px;
    color: white;
    background: transparent;
    border: none;
    border-bottom: 3px solid white;
    border-radius: 4px;
    font: {
      size: 20px;
      weight: bold;
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        transform: translate(.25rem, -65%) scale(.6);
      }
    }
    &:focus, &:active {
      outline: none;
    }
  }

  .label {
    position: absolute;
    left: 0;
    top: 20%;
    padding-bottom: 20px;
    margin: 9px 4px;
    color: white;
    transform: translateY(-50%);
    white-space: nowrap;
    transform-origin: 0 0;
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
    @media(max-width: $MOBILE_WIDTH) {
      padding-bottom: 10px;
    }
  }
}