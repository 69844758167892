@import 'src/variables/variables.scss';

.button {
  border: none;
  outline: none;
  color: white;
  font: {
    weight: bold;
    size: 32px;
  }
  box-sizing: border-box;
  padding: 26px 26px;
  border-radius: 32px;
  background: #FE9520;
  box-shadow: 0 0 26px 0 #fe9520;
  max-width: 560px;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  @media(max-width: $MOBILE_WIDTH) {
    font-size: 16px;
    padding: 16px 24px;
    margin: {
      top: 80px;
      right: auto;
      left: auto;
    };
    border-radius: 16px;
  }
}