@import 'src/variables/variables.scss';

.footer {
  margin: {
    top: 88px;
    bottom: 0;
  }
  background-color: #2A2A2A;

  .contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 33px 0 53px 0;
    flex-wrap: wrap;
    font-size: 20px;
    .contact {
      margin-right: 64px;
      margin-top: 20px;
      &:last-child {
        margin-right: 0;
      }
      & .contactLink {
        display: flex;
        align-items: center;
        white-space: nowrap;
        & > img {
          margin-right: 16px;
        }
      }
      @media (max-width: $MOBILE_WIDTH) {
        margin-right: 20px;
        font-size: 14px;
      }
    }
    @media (max-width: $MOBILE_WIDTH) {
      padding: 10px 10px 30px 10px;
    }
  }

  .designedBy {
    background-color: #FF951D;
    display: flex;
    justify-content: center;
    align-items: center;
    font: {
      family: 'Montserrat', sans-serif;
      size: 24px;
      weight: bold;
    }
    padding: 20px;
    @media (max-width: $MOBILE_WIDTH) {
      font-size: 16px;
      padding: 10px;
    }
    span:first-child {
      margin-right: 16px;
      @media (max-width: $MOBILE_WIDTH) {
        margin-right: 10px;
      }
    }
    .instagramLink {
      display: flex;
      align-items: center;
      & > img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }
}