@import 'src/variables/variables.scss';

.wrapper {
  .signUpButtonWrapper {
    display: flex;
    justify-content: center;
    .signUpButton {
      margin: {
        top: 60px;
        left: 30px;
        right: 30px;
      }
      @media(max-width: $MOBILE_WIDTH) {
        margin-top: 40px;
      }
    }
  }
}