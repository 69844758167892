@import 'src/variables/variables.scss';

.wrapper {
  max-width: 1220px;
  margin: 50px auto 0 auto;
  @media (max-width: $MOBILE_WIDTH) {
    //margin-top: 150px;
  }
  .header {
    text-align: center;
    font: {
      weight: bold;
      size: 56px;
    }
    margin: {
      top: 0;
      bottom: 64px;
    }
    padding-top: 20px;
    @media (max-width: $MOBILE_WIDTH) {
      font-size: 36px;
      margin-bottom: 36px;
    }
  }

  .blocks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1240px));
    grid-row-gap: 20px;
    grid-column-gap: 40px;
    justify-content: center;
    font: {
      size: 24px;
    }
    line-height: 28px;
    @media (max-width: $MOBILE_WIDTH) {
      margin: 0 20px;
      font-size: 20px;
    }
    section {
      display: flex;
      flex-direction: column;
      .textBlock {
        padding: 10px;
        text-align: justify ;
        margin: 0;
        & > p {
          text-indent: 8px;
          margin: 10px 0;
        }
        & .languages {
          text-indent: 0;
          margin-left: 8px;
        }
      }
    }
  }
}