.error {
  display: block;
  white-space: normal;
  font: {
    weight: 500;
    size: 16px;
  }
  left: 0;
  bottom: -20px;
  color: #E23428;
  padding: {
    top: 5px;
  }
}
